@charset "UTF-8";
@keyframes herocatchanime {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes heromain {
  0% {
    opacity: 0;
    transform: translate(-50px, 0);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}
@keyframes pageheromain {
  0% {
    clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
  }
  1% {
    clip-path: polygon(0 0, 10% 0, 20% 100%, 0 100%);
  }
  99% {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
  100% {
    clip-path: none;
  }
}
@keyframes rotation {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.a-rotate1 {
  animation: rotation 40s infinite linear 0.5s both;
}

.a-rotate2 {
  animation: rotation 20s infinite linear 0.5s both;
}

.a-bg-button1 {
  position: relative;
  border: solid 1px transparent;
  transition: 0.4s border;
}
.a-bg-button1 > span {
  z-index: 2;
  position: relative;
}
.a-bg-button1::before {
  content: " ";
  transition-duration: 0.4s;
  transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  left: auto;
  right: 0;
  z-index: 1;
  background-color: #fff;
}
.a-bg-button1:hover {
  border: solid 1px #2D53A3;
}
.a-bg-button1:hover span {
  color: #2D53A3;
}
.a-bg-button1:hover::before {
  width: 100%;
  right: auto;
  left: 0;
}
.a-bg-button1:hover .button-arrow {
  background-color: #2D53A3;
}

.a-bg-link {
  position: relative;
}
.a-bg-link > .inner {
  z-index: 2;
  position: relative;
}
.a-bg-link::before {
  content: "";
  transition-duration: 0.4s;
  transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  left: auto;
  right: 0;
  z-index: 1;
  background-color: #878787;
}
.a-bg-link:hover::before {
  width: 100%;
  right: auto;
  left: 0;
}
.a-bg-link:hover .icon {
  transform: translate(1rem, -50%);
}

.a-after-border {
  position: relative;
  transition: 0.3s;
}
.a-after-border::after {
  transition: 0.4s;
  content: "";
  height: 1px;
  width: 100%;
  border-bottom: 1px solid;
  display: block;
  transform: scale(0);
}
.a-after-border:hover::after {
  transform: scale(1);
}

.a-opcity-7 {
  transition: 0.3s;
}
.a-opcity-7:hover {
  opacity: 0.7;
}

@keyframes toLeft {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes toRight {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes imageOpacity {
  0% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
  50% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
  75% {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  }
}
.animate_to-left {
  transform: translateX(100%);
  opacity: 0;
  transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 1.2s;
}
.animate_to-left.is-active {
  opacity: 1;
  transform: translateX(0);
}

.animate_to-right {
  transform: translateX(-100%);
  transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 1.2s;
  opacity: 0;
}
.animate_to-right.is-active {
  opacity: 1;
  transform: translateX(0);
}

.animate_fadeUp {
  opacity: 0;
  transform: translateY(10px);
  transition: opacity cubic-bezier(0.645, 0.045, 0.355, 1) 0.8s;
}
.animate_fadeUp.is-active {
  opacity: 1;
  transform: translateY(0);
}

.animate_fadeUp2 {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity cubic-bezier(0.645, 0.045, 0.355, 1) 0.6s, transform cubic-bezier(0.645, 0.045, 0.355, 1) 0.6s, -webkit-transform cubic-bezier(0.645, 0.045, 0.355, 1) 0.6s;
}
.animate_fadeUp2.is-active {
  opacity: 1;
  transform: translateY(0);
}

.animate_fadeYItem {
  transform: translateY(10px);
  transition: opacity cubic-bezier(0.645, 0.045, 0.355, 1) 0.6s, transform cubic-bezier(0.645, 0.045, 0.355, 1) 0.6s, -webkit-transform cubic-bezier(0.645, 0.045, 0.355, 1) 0.6s;
  opacity: 0;
}
.animate_fadeYItem.is-active {
  opacity: 1;
  transform: translateY(0);
}
.animate_fadeYItem:nth-child(2) {
  transition-delay: 0.2s;
}
.animate_fadeYItem:nth-child(3) {
  transition-delay: 0.4s;
}
.animate_fadeYItem:nth-child(4) {
  transition-delay: 0.6s;
}
.animate_fadeYItem:nth-child(5) {
  transition-delay: 0.8s;
}
.animate_fadeYItem:nth-child(6) {
  transition-delay: 1s;
}

.animate_fadeXItem {
  transform: translateX(10px);
  transition: opacity cubic-bezier(0.645, 0.045, 0.355, 1) 0.6s, transform cubic-bezier(0.645, 0.045, 0.355, 1) 0.6s, -webkit-transform cubic-bezier(0.645, 0.045, 0.355, 1) 0.6s;
  opacity: 0;
}
.animate_fadeXItem.is-active {
  opacity: 1;
  transform: translateX(0);
}
.animate_fadeXItem:nth-child(2) {
  transition-delay: 0.2s;
}
.animate_fadeXItem:nth-child(3) {
  transition-delay: 0.4s;
}
.animate_fadeXItem:nth-child(4) {
  transition-delay: 0.6s;
}
.animate_fadeXItem:nth-child(5) {
  transition-delay: 0.8s;
}

.animate_ImgBgSlide {
  overflow: hidden;
  position: relative;
}
.animate_ImgBgSlide img {
  position: relative;
  z-index: 1;
  opacity: 0;
}
.animate_ImgBgSlide::before {
  transform: translateX(-100%);
  transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 1.2s;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(199, 198, 185, 0.7);
}
.animate_ImgBgSlide.is-active::before {
  transform: translateX(100%);
}
.animate_ImgBgSlide.is-active img {
  opacity: 1;
  animation: imageOpacity cubic-bezier(0.645, 0.045, 0.355, 1) 1.2s;
}

.animate_ImgSlide {
  overflow: hidden;
  position: relative;
  transform: translateX(-100%);
  transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 1.2s;
}
.animate_ImgSlide.is-active {
  transform: translateX(0);
}

.delay-2 {
  transition-delay: 0.2s;
}

.delay-5 {
  transition-delay: 0.5s;
}

/*
右から左へ
----------------------------*/
@keyframes infinity-scroll-left {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}
/*
左から右へ
----------------------------*/
@keyframes infinity-scroll-right {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0%);
  }
}
.a-infinity-scroll--left {
  animation: infinity-scroll-left 21s infinite linear 0.5s both;
}
@media screen and (max-width: 920px) {
  .a-infinity-scroll--left {
    animation: infinity-scroll-left 10s infinite linear 0.5s both;
  }
}

.a-infinity-scroll--left2 {
  animation: infinity-scroll-left 21s infinite linear 0.5s both;
}
@media screen and (max-width: 920px) {
  .a-infinity-scroll--left2 {
    animation: infinity-scroll-left 15s infinite linear 0.5s both;
  }
}

.a-infinity-scroll--right {
  animation: infinity-scroll-right 40s infinite linear 0.5s both;
}

.slide-visuals-wrap {
  overflow: hidden;
  display: flex;
}

.slide-visuals {
  display: flex;
}

.slide-visual {
  width: 32.3rem;
}
.slide-visual img {
  max-width: none;
  width: 100%;
}

/**scroll*/
@keyframes pathmove {
  0% {
    height: 0;
    top: 3rem;
    opacity: 0;
  }
  50% {
    height: 6rem;
    opacity: 1;
  }
  100% {
    height: 0;
    top: 12rem;
    opacity: 0;
  }
}
@keyframes popup {
  0% {
    transform: translateY(40px) scale(0.8);
    opacity: 0;
  }
  100% {
    transform: translateY(0) scale(1);
  }
  80%, 100% {
    opacity: 1;
  }
}
.a-popup1 {
  opacity: 0;
}
.a-popup1:nth-child(2) {
  animation-delay: 0.3s !important;
}
.a-popup1:nth-child(3) {
  animation-delay: 0.6s !important;
}
.a-popup1:nth-child(4) {
  animation-delay: 0.9s !important;
}

.bubbles.is-active .a-popup1 {
  animation: popup 0.6s cubic-bezier(0.22, 1, 0.36, 1) 1 forwards;
}

.a-popup2 {
  opacity: 0;
}

.a-popup2.is-active {
  animation: popup 1.6s cubic-bezier(0.22, 1, 0.36, 1) 1 forwards;
}

#svg_x5F_animation {
  animation: svg_animation 3s linear infinite;
  transform-origin: 50% 50%;
  -webkit-animation: svg_animation 1.5s linear infinite;
}

#svg6 #svg_x5F_animation {
  animation: none;
  -webkit-animation: none;
}

#svg6 {
  animation: svg_animation 3s linear infinite;
  transform-origin: 50% 50%;
  -webkit-animation: svg_animation 1.5s linear infinite;
}

@keyframes svg_animation {
  0% {
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
  }
  50% {
    transform: translateY(4px);
    -webkit-transform: translateY(4px);
    -moz-transform: translateY(4px);
    -ms-transform: translateY(4px);
    -o-transform: translateY(4px);
  }
}
#svg_x5F_animation2 {
  animation: svg_animation 3s linear infinite;
  transform-origin: 50% 50%;
  -webkit-animation: svg_animation2 1.5s linear infinite;
}

@keyframes svg_animation2 {
  0% {
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
  }
  50% {
    transform: translateX(-5px);
    -webkit-transform: translateX(-5px);
    -moz-transform: translateX(-5px);
    -ms-transform: translateX(-5px);
    -o-transform: translateX(-5px);
  }
}
#svg_x5F_animation3 {
  animation: svg_animation 3s linear infinite;
  transform-origin: 50% 50%;
  -webkit-animation: svg_animation3 1.5s linear infinite;
}

@keyframes svg_animation3 {
  0% {
    fill: #fff;
  }
  50% {
    fill: #db8c88;
  }
}
#svg_x5F_animation4 {
  animation: svg_animation 3s linear infinite;
  transform-origin: 50% 50%;
  -webkit-animation: svg_animation4 1.5s linear infinite;
}

@keyframes svg_animation4 {
  0% {
    transform: translateX(0px) translateY(0px);
    -webkit-transform: translateX(0px) translateY(0px);
    -moz-transform: translateX(0px) translateY(0px);
    -ms-transform: translateX(0px) translateY(0px);
    -o-transform: translateX(0px) translateY(0px);
  }
  50% {
    transform: translateX(2px) translateY(5px);
    -webkit-transform: translateX(2px) translateY(5px);
    -moz-transform: translateX(2px) translateY(5px);
    -ms-transform: translateX(2px) translateY(5px);
    -o-transform: translateX(2px) translateY(5px);
  }
}
